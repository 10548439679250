import "./App.css";
import { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

function OrganizationChartPage() {
  const [orgData, setOrgData] = useState(null);

  async function fetchOrgChart() {
    const url = "/organization-chart";

    fetch(url)
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      //.then(data => console.log(data))
      .then((data) => setOrgData(data))
      .catch((error) => console.error("Error:", error));
  }

  useEffect(() => {
    fetchOrgChart();
  }, []);

  return (
    <>
      <div>
        <h1>ACME CORP ORG CHART.</h1>
        <p style={{ lineHeight: "1.5em"}}>
          Below is a vertical organization chart for employees of ACME CORP. The
          chart has been expanded by default. Click the name of a department or
          manager to toggle the department's employees or the manager's reports.
        </p>
      </div>
      {orgData
        ? orgData.organization.departments.map((department) => (
            <details style={{ marginBottom: "1em" }} open>
              <summary>
                <span style={{ fontSize: "1.5em", fontWeight: "bold" }}>
                  {department.name}
                </span>
                <div style={{ marginLeft: "1em" }}>
                  <div>Manager: {department.managerName}</div>
                </div>
              </summary>
              <div style={{ marginLeft: "1em", marginTop: "2em" }}>
                {[
                  department.employees.find(
                    (employee) => employee.isManager === true
                  ),
                ].map((manager) => (
                  <details open>
                    <summary>
                      <span style={{ fontSize: "1em", fontWeight: "bold" }}>
                        {manager.name}
                      </span>
                      <div style={{ marginLeft: "1em", marginTop: "0.5em" }}>
                        <div>Office: {manager.office}</div>
                        <div>Skills: {manager.skills.join(", ")}</div>
                        <div>Salary: {manager.salary}k</div>
                      </div>
                    </summary>
                    <div
                      style={{
                        marginTop: "1em",
                        marginLeft: "1em",
                      }}
                    >
                      <details open>
                        <summary style={{ marginLeft: "1em" }}>Reports</summary>
                        <div
                          style={{
                            marginLeft: "2em",
                            marginTop: "2em",
                          }}
                        >
                          {department.employees
                            .filter((employee) => employee.isManager === false)
                            .map((employee) => (
                              <div
                                style={{
                                  marginBottom: "2em",
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: "1em",
                                    fontWeight: "bold",
                                    marginBottom: "0.5em",
                                  }}
                                >
                                  {employee.name}
                                </div>
                                <div>Office: {employee.office}</div>
                                <div>Skills: {employee.skills.join(", ")}</div>
                                <div>Salary: {employee.salary}k</div>
                              </div>
                            ))}
                        </div>
                      </details>
                    </div>
                  </details>
                ))}
              </div>
            </details>
          ))
        : "Loading"}
    </>
  );
}

function App() {
  return (
    <div style={{ padding: "2em" }}>
      <Router>
        <Routes>
          <Route path="/orgchart" element={<OrganizationChartPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
